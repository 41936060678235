<template>
  <div>
    <hospital-profile-component @update-title="changeTitle" />
  </div>
</template>

<script>
import HospitalProfileComponent from "@/components/hospitals-clinics/HospitalProfileComponent";

export default {
  name: "HospitalProfile",
  title: "Hospital Profile | Baja California Health Tourism",
  components: {
    HospitalProfileComponent,
  },
  methods: {
    changeTitle(e) {
      this.title = e;
      document.title = `${e} | Baja California Health Tourism`;
    }
  }
};
</script>

<style></style>
