<template>
    <v-card hover class="rounded-lg">
        <div style="cursor: pointer; height: 300px; overflow: hidden" v-on:click="showProfile(item.slu)">

            <v-img :src="item.pictures_portal[0] != null
                    ? item.pictures_portal[0].url
                    : require('@/assets/images/no-image.jpg')
                " :lazy-src="require('@/assets/images/no-image.jpg')"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" aspect-ratio="1" height="300"
                class="rounded-lg white--text align-end justify-start elevation-2" @click="showProfile(item.slu)">

                <v-btn dark plain text elevation="0" class="ma-2" v-on:click="showProfile(item.slu)">
                    <span class="text-uppercase font-weight-bold" v-text="item.name"></span>
                </v-btn>

                <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5">
                        </v-progress-circular>
                    </v-row>
                </template>
            </v-img>
            <TypeEntityView :item="item" />
        </div>
    </v-card>
</template>
<script>
import TypeEntityView from "@/components/type-entity-view/TypeEntityView";

export default {
    name: "CardHospital",
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    components: {
        TypeEntityView
    },
    methods: {
        showProfile(idHospital) {
            this.$router.push({
                name: "HospitalProfile",
                params: {
                    id: idHospital,
                },
            });
            this.$emit("update-route", { id: idHospital });
        },
    }
}
</script>
